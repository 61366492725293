jQuery(document).ready(function ($) {
    $(".tab_content").hide();
    $(".tab_content:first").show();
    $("ul.tabs li").click(function () {
        $(".tab_content").hide();
        var activeTab = $(this).attr("rel");
        $("#" + activeTab).fadeIn();
        $("ul.tabs li").removeClass("active");
        $(this).addClass("active");
        $(".tab_drawer_heading").removeClass("d_active");
        $(".tab_drawer_heading[rel^='" + activeTab + "']").addClass("d_active");
        $('html, body').animate({
            scrollTop: $("#" + activeTab).offset().top
        }, 500);
    });
    $(".tab_drawer_heading").click(function () {
        $(".tab_content").hide();
        var d_activeTab = $(this).attr("rel");
        $("#" + d_activeTab).fadeIn();
        $(".tab_drawer_heading").removeClass("d_active");
        $(this).addClass("d_active");
        $("ul.tabs li").removeClass("active");
        $("ul.tabs li[rel^='" + d_activeTab + "']").addClass("active");
    });
    $('ul.tabs li').last().addClass("tab_last");
    $('.count').counterUp({delay: 10, time: 2000});
    $('#menu').singlePageNav({filter: ':not(.extra)'});
    $(window).on('scroll', function () {
        var b = $(window).scrollTop();
        if (b > 60) {
            $(".scroll_header").addClass("scrollMenu");
        } else {
            $(".scroll_header").removeClass("scrollMenu");
        }
    });
    $('.accordion_title').click(function () {
        $(this).toggleClass('active').next().slideToggle(300);
    });
});

$(document).ready(function () {
    $('#menu').prettyMenu({
        colorScheme: 'white',
        submenuAnimation: 'zoomIn',
        hoverColor: 'transparent',
        shadow: false,
        showDividers: false,
        pushContent: true,
        closeOnClick: true,
        fixed: false,
        mobileBackground: false
    });
});